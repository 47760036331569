<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
            <div><img :src="require('@/assets/image/web/ico_logo_large.svg')" /></div>
            <div class="mt-20">
                <img :src="require('@/assets/image/web/ico_logo_word.svg')" />
            </div>
            <div class="mt-20">문화예술 전용 커뮤니티 <b>마피아 프로젝트</b></div>
        </div>

		<div class="intro_btn_wrap pa-30">
			<div class="social_login">

				<button
					class="btn btn-kakao"
					@click="getDeviceInfo('kakao')"
				>Kakao 로그인</button>

				<button
					class="btn btn-google mt-15"
					@click="getDeviceInfo('google')"
					:disabled="!isInit"
				>Google 로그인</button>

				<button
					class="btn btn-apple mt-15"
					@click="getDeviceInfo('apple')"
				>Apple 로그인</button>

				<vue-apple-login
					v-show="false"

					:onSuccess="handleAppleLoginSuccess"
					:onFailure="handleAppleLoginFail"
				></vue-apple-login>
			</div>

			<button
				@click="$emit('to', { name: 'mafia004'})"
				class="color-white mt-20"
			>회원가입</button>

			<p
				class="copyright mt-20 color-gray"
			>Copyright © MAFI Inc. </p>
		</div>
	</div>
</template>

<script>
import { v4 as uuidvr4 } from 'uuid'
export default {
	name: 'mafia011'
	, props: ['user']
	, components: { }
	,data: function(){
		return {
			program: {
				name: '로그인'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
			,kakao_info: null
			,isInit: false
			,isSignIn: false
			, social_code: ''
			, state: '1234'
			,is_pin: false
			,pin_option: {
				pin_type: 'check'
			}
			, is_otp: false
			, is_auto: this.$route.params.auto
			, login_type: ''
		}
	}
	, computed: {
		kakao_return_url: function(){

			let domain = process.env.VUE_APP_DOMAIN
			let dev = process.env.VUE_APP_DEV
			let local = 'https://localhost:8080'
			let location = window.location.href
			let url = ''

			this.$log.console('location', location)
			if(location.indexOf(domain) > -1){
				url = domain
			}else if(location.indexOf(dev) > -1){
				url = dev
			}else{
				url = local
			}

			return url + '/auth/login/kakao'
		}
	}
	,methods: {
		loginWith: async function(){
			let type = this.login_type
			console.log('loginWith')
			if(type == 'kakao'){
				this.social_code = 'MB00400001'
				try{
					await this.handleSignInKakao()
				}catch(e){
					this.$log.console('kakao login error', e)
				}

			}else if(type == 'google'){
				this.social_code = 'MB00400003'
				try{
					await this.handleClickUpdateScope2()
				}catch(e){
					this.$log.console('google login error', e)
				}
			}else if(type == 'apple'){
				this.social_code = 'MB00400002'
				// next
				try{
					document.getElementById('appleid-signin').click()
				}catch(e){
					this.$log.console('apple login error', e)
				}
			}
		}
		, async handleSignInKakao() {
			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}
			
			let t = false
			if(t){
				let REST_API_KEY = process.env.VUE_APP_KEY_KAKAO_REST
				let REDIRECT_URI = this.kakao_return_url
				window.document.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&state=1234`
			}else{
				this.$log.console(window.Kakao.isInitialized());
				this.$log.console('kakao token: ' + window.Kakao.Auth.getAccessToken())
				await this.kakaoLogin()
			}
		}
		,async handleClickUpdateScope2() {
			await this.$gAuth.signOut();
			this.$log.console('handleClickUpdateScope2')

			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = await this.$gAuth.GoogleAuth.currentUser.get()
			let t = googleUser.getAuthResponse();

			try {
				let result = await googleUser.grant(option);
				this.$log.console("result", result);

				if(!t.access_token){
					t = googleUser.getAuthResponse();
					this.$log.console("t re!!", t);
				}

				let r = {
					type : 'google'
					, access_token: t.access_token
				}

				await this.loginWithSocial(r);

				//await this.joinWithGoogle(googleUser.Cc.access_token, googleUser.Av.mw)
			} catch (error) {
				console.error(error);
			}
		}

		,kakaoLogin: async function(){

			window.Kakao.Auth.loginForm({
				success: () => {
					/*
					this.kakao_info = authObj
					this.$log.console(this.kakao_info, authObj)
					this.$log.console('kakao login token', window.Kakao.Auth.getAccessToken())
					 */
					let r = {
						type: 'kakao'
						, access_token: window.Kakao.Auth.getAccessToken()
					}

					this.loginWithSocial(r)
				},
				fail: function(err) {
					this.$log.console(err)
				},
			})
		}

		, loginWithSocial: async function(e){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_login_social
					, data: {
						member_social_certfc_code: this.social_code
						, social_certfc_code: e.access_token
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					this.$log.console('this.token_info', this.token_info)

					sessionStorage.removeItem('Error')

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}

					switch (result.data.member_state_code){
						default:
							this.$emit('to', {name: 'mafia009'})
							break;
						case 'MB00500001':
							this.$emit('to', {name: 'sms'})
							break;
						case 'MB00500002':
							this.$bus.$emit('onPin', 'join')
							break;
						case 'MB00500003':
							this.$emit('to', {name: 'mafia0101'})
							break;
						case 'MB00500004':
							this.$emit('to', {name: 'nice'})
							break;
						case 'MB00500005':

							if(result.data.session_token){
								await this.$encodeStorage.setSessionToken(result.data.session_token)
							}
							await this.$encodeStorage.setItem('login_info', e)
							this.onPin('pin_login')
							//this.$bus.$emit('setUser', result.data)
							break;
						case 'MB00500006':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
							break;
						case 'MB00500007':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
							break;
						case 'MB00500008':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
							break;
					}
				}else{
					this.$log.console(result)
					if((result.code).indexOf('E0014') > -1){
						await this.$encodeStorage.removeItem('login_info')
						throw '인증이 만료되었습니다. 다시 로그인하여 주세요'
					}else {

						switch (result.code) {
							case 'E000400012':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								break;
							case 'E000400013':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
								break;
							case 'E000400014':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								break;
							case 'E001400003':
							case 'E001400004':
							case 'E001400008':
								await this.$encodeStorage.removeItem('login_info')
								throw '인증이 만료되었습니다. 다시 로그인하여 주세요'
							default:
								await this.$encodeStorage.removeItem('login_info')
								throw result.message
						}
					}
				}
			}catch (e) {
				this.$log.console(e)
				this.error = e

				this.$emit('to', { name: 'mafia009', params: { type: 'third', message: e}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,async handleClickUpdateScope() {
			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = this.$gAuth.GoogleAuth.currentUser.get();
			try {
				await googleUser.grant(option);
				this.$log.console(googleUser)
				this.$log.console("success");
			} catch (error) {
				console.error(error);
			}
		},
		handleClickLogin() {
			this.$gAuth
				.getAuthCode()
				.then((authCode) => {
					//on success
					this.$log.console("authCode", authCode);
				})
				.catch((error) => {
					this.$log.console(error)
				});
		},
		async handleClickSignIn() {
			try {
				const googleUser = await this.$gAuth.signIn();
				if (!googleUser) {
					return null;
				}
				this.$log.console("googleUser", googleUser);
				this.$log.console("getId", googleUser.getId());
				this.$log.console("getBasicProfile", googleUser.getBasicProfile());
				this.$log.console("getAuthResponse", googleUser.getAuthResponse());
				this.$log.console(
					"getAuthResponse",
					this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
				);
				this.isSignIn = this.$gAuth.isAuthorized;
			} catch (error) {
				//on fail do something
				console.error(error);
				return null;
			}
		},
		async handleClickSignOut() {
			try {
				await this.$gAuth.signOut();
				this.isSignIn = this.$gAuth.isAuthorized;
				this.$log.console("isSignIn", this.$gAuth.isAuthorized);
			} catch (error) {
				console.error(error);
			}
		},
		handleClickDisconnect() {
			window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
		}
		, onOtp: function(){
			this.is_pin = false
			this.is_otp = true
		}
		, postOtp: async function(gogle_otp_certfc_number){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_otp
					, data: {
						gogle_otp_certfc_number: gogle_otp_certfc_number
					}
					, type: true
				})

				if(result.success){
					this.onPin('update')
				}else{
					throw result.message
				}
			}catch (e){
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, onPin: function(pin_type){
			this.$bus.$emit('onPin', pin_type)
		}
		, offPin: function(){
			this.is_pin = false
			this.pin_option.pin_type = 'check'
		}
		, handleAppleLoginSuccess: function(data){
			this.$log.console('handleAppleLoginSuccess', data)
			this.social_code = 'MB00400002'
			let e = {
				type: 'apple'
				, access_token: data.authorization.code
			}
			this.loginWithSocial(e)
		}
		, handleAppleLoginFail: function(error){
			this.$log.console(error)
			this.$bus.$emit('notify', { type: 'error', message: this.$language.auth.login_fail_apple})
		}
		, getDeviceInfo: async function(type){
			this.login_type = type
			console.log('in getDeviceInfo')
			// 디바이스 정보
			try{
				// 디바이스 uuid
				let uuid = this.$encodeStorage.getItem('_uuid')

				if(!uuid){
					uuid = uuidvr4()
					await this.$encodeStorage.setItem('_uuid', uuid)
				}

				let device_info = {
					"conn_div_code": this.$common.isMobile(),
					"uniq_device_id": uuid,
					"mobile_opsys_code": this.$common.getOsCode(),
					"opsys_version": "",
					"push_id": uuid,
					"app_version": "1.0.12",
					"conn_ip": await this.$common.getIp()
				}
				await this.getToken(device_info)
			}catch (e){
				console.log('디바이스 정보', e)
			}
		}

		, getToken: async function(token){

			console.log('postDeviceToken')
			try {

				const result = await this.$Request({
					method: 'POST'
					, url: this.$api_url.api_path.post_device_token
					, data: token
					, type: true
				})
				if (result.success) {

					this.item_app_info = result.data

					for(let [k, v] of Object.entries(this.item_app_info)){
						//alert(k + ':' + v)
						console.log(k, v)
					}
					try{
						window.android.postMessage(this.item_app_info.app_execu_code)

						if(this.item_app_info.app_execu_code && this.item_app_info.app_execu_code != 'AP00500001'){
							this.is_version = true
						}else{
							throw ''
						}
					}catch (e){
						this.certfc = result.data
						await this.$encodeStorage.setSessionCertfc(this.certfc)
						await this.$encodeStorage.setSessionVersion(token.app_version)
						await this.loginWith()
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)

				await this.$router.push({ name: 'mafia009', params: { type: 'third', msg: e}})
			}
		}
	}
	,async created() {
		// this.$log.console('auth login user', this.user)
		let at = this.$encodeStorage.getSessionAt()
		if(at){
			await this.$router.push({ name: 'main'})
		}else{

			this.$emit('onLoad', this.program)

			let checkGauthLoad = setInterval(() => {
				this.isInit = this.$gAuth.isInit;
				this.isSignIn = this.$gAuth.isAuthorized;
				if (this.isInit) clearInterval(checkGauthLoad);
			}, 1000);

			this.$log.console('this.is_auto', this.is_auto)
			if(this.is_auto){
				let login_info = this.$encodeStorage.getItem('login_info')
				this.$log.console('login_info', login_info)
				if(login_info){

					this.onPin('pin_login')

					/*
					let e = {
						access_token: login_info.access_token
						, type: login_info.type
					}
					switch (login_info.type){
						case 'kakao':
							this.social_code = 'MB00400001'
							break
						case 'google':
							this.social_code = 'MB00400003'
							break
						case 'apple':
							this.social_code = 'MB00400002'
							break
						default:
							return false
					}
					await this.loginWithSocial(e)
					 */

				}else{
					return false
				}
			}else{
				this.$bus.$emit('getDeviceToken')
			}

			this.$bus.$on('pinCallback', (pin_type) => {
				switch (pin_type){
					case 'login':
						break
					case 'update':
						break
					case 'join':
						this.$emit('to', { name: 'mafia0101'})
						break
				}
				this.$bus.$off('pinCallback')
			})

			window.sign_in_social = (e) => {
				try{
					this.$log.console(e.type)
					this.$log.console(e.access_token)
					this.$log.console(e.email)
					this.loginWithSocial(e)
				}catch (e) {
					this.$log.console(e)
				}
			}

			window.fail_in_social = (e) => {
				this.$log.console(e)
				this.$encodeStorage.removeItem('login_info')
				this.is_auto = false
				this.$router.push({ name: 'mafia009', params: { type: 'third', msg: '소셜 인증 오류 ' + e.error }})
			}
		}
	}
	,watch: {

	}
}
</script>

<style>
.btn-kakao, .btn-apple {
	color:  #333;
}
</style>